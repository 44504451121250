html, body {
    margin: 0;
}
body {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
}

