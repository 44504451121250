body {
    /* overflow: hidden; */
    margin: 0;
}

.main {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 25px;
    /* height: 100%; */
}

.orderSection {
    margin-top: 13%;
}

.orderText {
    color: white;
    margin-left: 32px;
    font-size: 25px;
}

.orderImage {
    margin-left: 50px;
}

.container {
    position: relative;
}

.card {
    background-color: white;
    border-radius: 20px;
    padding-top: 40px;
}

.or-in {
    margin-left: 13px;
    line-height: 45px;
    width: 87%;
    margin-bottom: 25px;
    border-radius: 10px;
    border: 1px solid #ccc;
    border-top: none;
    outline: none;
    font-size: 20px;
    padding-left: 24px;
}

#textarea {
    height: 100px;
}

label {
    position: absolute; 
    top: -10px;
    left: 40px;
    z-index: 2;
    font-size: 15px;
}